import type { StoryBlokMedia } from "@/types/storyblok";

import StoryblokImage from "./StoryblokImage";
import styles from "./storyblokMedia.module.scss";
import Video from "./Video";

interface UIProps {
  media: StoryBlokMedia;
  sizes?: string;
}

const videoTypes = [
  ".mp4",
  ".mov",
  ".avi",
  ".wmv",
  ".flv",
  ".3gp",
  ".mkv",
  ".webm",
];

const UI = ({ media, sizes }: UIProps) => {
  const isVideo =
    media.file && videoTypes.some((type) => media.file.includes(type));
  return (
    <div className={styles.wrapper}>
      {isVideo ? (
        <Video media={media} key={media.file} />
      ) : (
        <StoryblokImage media={media} key={media.file} sizes={sizes} />
      )}
    </div>
  );
};

export default UI;
